<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Support us</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="my-3 text-center">
            <div>Like using Trainifier?<br>Buy us coffee ☕️ 😁</div>
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm mt-3"
              role="status"
              aria-hidden="true"
            ></span>
            <a
              @click="close"
              v-if="!isLoading && paymentUrl"
              :href="paymentUrl"
              class="btn btn-sm btn-outline-primary mt-3"
              target="_blank"
            >Sure here's $2</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close(forced = false) {
      if (this.isLoading && forced !== true) return;
      this.$emit('close');
    },
    async fetchLink() {
      try {
        this.isLoading = true;
        const { data: { url } } = await httpGet('/subscription/webdonation');
        this.paymentUrl = url;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      paymentUrl: null,
    };
  },
  mounted() {
    this.fetchLink();
  },
};
</script>
